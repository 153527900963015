import * as Cookies from 'js-cookie'
import {checkNull} from "@/helpers";

// expires after 30 days
const cookieConsentName = 'cookie_consent';
// expires after browser session
const cookieNavHistoryName = 'navHistory';
// expires after 30 days
const cookieSdConfigName = 'sd_config';
// expires after 30 days
const cookieORegisterName = 'o_register';
// expires after 30 days
const cookieOUserName = 'o_user';

const daysToDate = (days) => new Date(new Date().getTime() + (days * 24 * 3600 * 1000))

function defaultConsent() {
	let consent = Cookies.get(cookieConsentName) || false
	// verify old consent cookie name
	if (Cookies.get('cookies_consent')) {
		if (!consent) {
			consent = Cookies.get('cookies_consent');
			Cookies.set(cookieConsentName, consent, {expires: daysToDate(30), secure: !is_dev, sameSite: 'lax'});
			Cookies.remove('cookies_consent');
		}
	}
	if (consent !== false) {
		return consent.split(',');
	}
	return ['technical'];
}

function defaultConsentApproved() {
	return !!Cookies.get(cookieConsentName)
}

function defaultJsonCookie(cookieName) {
	let cookie = checkNull(Cookies.get(cookieName)) || "{}"
	return JSON.parse(cookie)
}

const is_dev = process.env.NODE_ENV !== 'production'

export default {
	namespaced: true,
	state: {
		consent: defaultConsent(),
		consent_approved: defaultConsentApproved(),
		sd_config: defaultJsonCookie(cookieSdConfigName),
		o_register: defaultJsonCookie(cookieORegisterName),
		o_user: defaultJsonCookie(cookieOUserName),
		is_finished_register: false
	},
	getters: {
		getConsent(state) {
			return state.consent
		},
		isConsentApproved(state) {
			return state.consent_approved
		},
		consentFunctional(state) {
			return !window.APP_CONFIG.COOKIE_POPUP || state.consent.includes('functional')
		},
		consentPerformance(state) {
			return !window.APP_CONFIG.COOKIE_POPUP || state.consent.includes('performance')
		},
        consentAdvertising(state) {
            return !window.APP_CONFIG.COOKIE_POPUP || state.consent.includes('advertising')
        },
		consentAdvertisingPersonalization(state) {
			return !window.APP_CONFIG.COOKIE_POPUP || state.consent.includes('advertising_personalization')
		},
		getSdConfig(state) {
			return state.sd_config
		},
		hasSdConfig(state) {
			return state.sd_config && Object.keys(state.sd_config).length
		},
		getORegister(state) {
			return state.o_register
		},
		hasORegister(state) {
			return state.o_register && Object.keys(state.o_register).length
		},
		getOUser(state) {
			return state.o_user
		},
		hasOUser(state) {
			return state.o_user && Object.keys(state.o_user).length
		},
		getIsFinishedRegister(state) {
			return state.is_finished_register
		}
	},
	mutations: {
		setConsent(state, payload) {
			state.consent = payload
		},
		setConsentApproved(state, payload) {
			state.consent_approved = payload
		},
		setSdConfig(state, payload) {
			state.sd_config = payload
		},
		setORegister(state, payload) {
			state.o_register = payload
		},
		setOUser(state, payload) {
			state.o_user = payload
		},
		setIsFinishedRegister(state, payload) {
			state.is_finished_register = payload
		}
	},
	actions: {
		saveConsent(context, payload) {
			context.commit('setConsent', payload);
			context.commit('setConsentApproved', true);
			Cookies.set(cookieConsentName, payload.join(','), {expires: daysToDate(30), secure: !is_dev, sameSite: 'lax'});
			if (!context.getters.consentFunctional) {
				Cookies.remove(cookieNavHistoryName);
			}
			if (!context.getters.consentAdvertising) {
				Cookies.remove(cookieSdConfigName);
			} else {
				context.dispatch('saveSdConfig', true)
			}
		},
		saveNavHistory(context, payload) {
			if (context.getters.consentFunctional) {
				let navHistory = Cookies.get(cookieNavHistoryName);
				navHistory = navHistory ? JSON.parse(navHistory) : [];
				navHistory.unshift(payload);
				Cookies.set(cookieNavHistoryName, navHistory.slice(0, 9), {secure: !is_dev, sameSite: 'lax'});
			} else {
				Cookies.remove(cookieNavHistoryName);
			}
		},
		saveSdConfig(context, payload) {
			let a_keys = ['a', 'p', 'i', 's1', 's2', 's3', 's4', 'tracking_id', 'cid', 'gclid']
			let params = {...context.getters.getSdConfig};
			let modified = false;
			if(typeof payload === 'object' && payload !== null) {
				for (let key in payload) {
					if (a_keys.indexOf(key) !== -1) {
						modified = true;
						params[key] = payload[key]
					}
				}
			} else {
				modified = true;
			}
			if (modified) {
				context.dispatch('setSdConfig', params);
			}
		},
		setJsonCookie(context, {commitName, cookieName, value, expires, consent}) {
			consent = typeof consent !== 'undefined' ? consent : true;
			if (value && typeof value === 'object' && Object.keys(value).length) {
				context.commit(commitName, value);
				if (consent) {
					Cookies.set(cookieName, value, {expires, secure: !is_dev, sameSite: 'lax'})
				}
			} else {
				context.commit(commitName, {});
				Cookies.remove(cookieName);
			}
		},
		setSdConfig(context, payload) {
			context.dispatch('setJsonCookie', {
				commitName: 'setSdConfig',
				cookieName: cookieSdConfigName,
				value: payload,
				expires: daysToDate(30),
				consent: context.getters.consentAdvertising
			})
		},
		setORegister(context, payload) {
			context.dispatch('setJsonCookie', {
				commitName: 'setORegister',
				cookieName: cookieORegisterName,
				value: payload,
				expires: daysToDate(30)
			})
		},
		setOUser(context, payload) {
			context.dispatch('setJsonCookie', {
				commitName: 'setOUser',
				cookieName: cookieOUserName,
				value: payload,
				expires: daysToDate(30)
			})
		}
	}
}
